const API_ENDPOINTS = {
  // In Dashboard
  Order_counts: `/counts/orders_counts`,
  Filter_api_salesGroup: `/api/filter_api_salesgroup/`,
  // In All order
  Api_order_taking: `/api/orderTaking/all-orders-items/order-by-dashboard`,
  Api_all_order_view: `/api/all_order_view`,
  Invoice_generator: `/api/invoice_genrator_api`,
  Delete_orders: `/api/delete-order-dashboard`,
  Edit_orders: `/api/items-cd-edit`,
  // In Gift
  Gift_remark_edit: `/api/edit_remarks_giftdashboard`,
  Gift_remark_Delete: `/api/delete_giftdashboard_api`,
  Gift_api_all: `/api/gift_api_all`,
  Gift_api_all_payerId: `/api/gift_api_payerid`,
  //In GroupByOrderDetail
  Tc_Pc_kg_pkt_counts: `api/tc_pc_kg_pkt_counts`,
  Expens_working_days_range: `api/expense/working_days_range`,

  // GET_USERS: "/users",
  // GET_PRODUCTS: "/products",
  // GET_ORDERS: "/orders",
  // CREATE_USER: "/users",
  // UPDATE_USER: (id) => `/users/${id}`,
  // DELETE_USER: (id) => `/users/${id}`,
};

export default API_ENDPOINTS;
