import React, { useEffect, useState, useCallback } from "react";
import {
  Box,
  Card,
  CardContent,
  Grid,
  Stack,
  TextField,
  Button,
  Autocomplete,
} from "@mui/material";
import API_ENDPOINTS from "../../apiServices/apiEndpoints";
import Tooltip from "@mui/material/Tooltip";
import ArticleIcon from "@mui/icons-material/Article";
import toast, { Toaster } from "react-hot-toast";
import StorefrontIcon from "@mui/icons-material/Storefront";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import FilterListIcon from "@mui/icons-material/FilterList";
import SmallCard from "./SmallCard/SmallCard";
import BarChart from "./Graph/BarChart";
import ItemIdChart from "./Graph/ItemIdChart";
import StateOrderChart from "./Graph/StateOrderChart";
import WeeklyRevenueChart from "./Graph/WeeklyRevenueChart";
import {
  getRequestInstanceOne,
  getRequestInstanceThree,
  getRequestInstanceTwo,
} from "../../apiServices/apiCall";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import CustomDialog from "../../components/CustomDialog/CustomDialog";
import TotalRevenue from "./Graph/TotalRevenue";
import AreaWiseWeeklySalesBarChart from "./Graph/AreaWiseWeeklySalesBarChart";
import { DateRange } from "@mui/icons-material";

const Dashboard = () => {
  const today = new Date().toISOString().split("T")[0];
  const [smallCardCountData, setSmallCardCountData] = useState({
    no_order_count: 0,
    order_count: 0,
    payerId_counts: 0,
    total_rid_counts: 0,
  });
  const [salesgroups, setSalesgroups] = useState([]);
  const [selectedSalesgroup, setSelectedSalesgroup] = useState("");
  const [isDateValid, setIsDateValid] = useState(true);
  const [stockist, setStockist] = useState([]);
  const [selectedStockist, setSelectedStockist] = useState("");
  const [payerIds, setPayerIds] = useState([]);
  const [selectedPayerIds, setSelectedPayerIds] = useState("");
  const [names, setNames] = useState([]);
  const [selectedNames, setSelectedNames] = useState("");
  const [openFilterDialog, setOpenFilterDialog] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("Today");
  const [openDialog, setOpenDialog] = useState(false);
  const [customDateRange, setCustomDateRange] = useState({
    start_date: today,
    end_date: today,
  });
  const [dateRange, setDateRange] = useState({
    start_date: today,
    end_date: today,
  });
  const [formData, setFormData] = useState({
    start_date: today,
    end_date: today,
    salesgroup: "",
    stockist: "",
    names: "",
    itemId: "",
  });
  const theme = useTheme();
  // const [workingDaysData, setWorkingDaysData] = useState(null);
  const userRole = localStorage.getItem("user");
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  // const isTab = useMediaQuery(theme.breakpoints.down("md"));
  const smallCardData = [
    {
      name: "Orders",
      count: smallCardCountData.order_count,
      icon: <ArticleIcon sx={{ color: "#228b22" }} />,
      color: "#228b22",
    },
    {
      name: "Retailer Orders",
      count: smallCardCountData.total_rid_counts,
      icon: <StorefrontIcon sx={{ color: "#00ced1a8" }} />,
      color: "#00ced1",
    },
    {
      name: "Payers",
      count: smallCardCountData.payerId_counts,
      icon: <AppRegistrationIcon sx={{ color: "#ba55d3" }} />,
      color: "#ba55d3",
    },
    {
      name: "No Orders",
      count: smallCardCountData.no_order_count,
      icon: <EventBusyIcon sx={{ color: "#ff000065" }} />,
      color: "#FF0000",
    },
  ];

  const getOrderCount = async () => {
    let url = API_ENDPOINTS.Order_counts;
    // Conditionally add the user parameter if payerId exists
    const params = userRole !== "admin" ? { user: userRole } : "";
    try {
      const result = await getRequestInstanceOne(url, params);
      setSmallCardCountData(result);
    } catch (error) {
      console.error(error);
    }
  };

  const getSalesgroupData = async (start_date, end_date) => {
    const today = new Date(); // Initialize today
    // const endpoint = "/api/filter_api_salesgroup/";
    const endpoint = API_ENDPOINTS.Filter_api_salesGroup;

    let params = {
      start_date:
        start_date == null && end_date == null
          ? (end_date = start_date = today.toISOString().split("T")[0])
          : start_date,
      end_date:
        start_date == null && end_date == null
          ? (end_date = start_date = today.toISOString().split("T")[0])
          : end_date,
      ...(userRole !== "admin" ? { user: userRole } : {}),
    };

    try {
      const response = await getRequestInstanceOne(endpoint, params);
      setSalesgroups(response.salesgroup); // Ensure setSalesgroups is available
    } catch (error) {
      console.error("Error fetching salesgroup data:", error);
    }
  };

  useEffect(() => {
    getOrderCount();
    getSalesgroupData(customDateRange.start_date, customDateRange.end_date);
  }, [customDateRange]);

  const handleFilterSelect = (filter) => {
    setSelectedSalesgroup("");
    setSelectedStockist("");
    setSelectedNames("");
    setSelectedFilter(filter);
    const today = new Date();

    let start_date, end_date;

    switch (filter) {
      case "Today":
        start_date = end_date = today.toISOString().split("T")[0];
        getSalesgroupData(start_date);
        break;
      case "Weekly":
        start_date = new Date(today.setDate(today.getDate() - 7))
          .toISOString()
          .split("T")[0];
        end_date = new Date().toISOString().split("T")[0];
        getSalesgroupData(start_date, end_date);

        break;
      case "Monthly":
        start_date = new Date(today.setMonth(today.getMonth() - 1))
          .toISOString()
          .split("T")[0];
        end_date = new Date().toISOString().split("T")[0];
        getSalesgroupData(start_date, end_date);

        break;
      case "Yearly":
        start_date = new Date(today.setFullYear(today.getFullYear() - 1))
          .toISOString()
          .split("T")[0];
        end_date = new Date().toISOString().split("T")[0];
        getSalesgroupData(start_date, end_date);

        break;
      case "Custom Range":
        setOpenDialog(true);
        return;
      default:
        return;
    }

    setCustomDateRange({
      start_date,
      end_date,
    });
    getSalesgroupData(customDateRange.start_date, customDateRange.end_date);

    getOrderCount(start_date, end_date);
  };

  const handleDateChange = (field) => (event) => {
    const selectedDate = new Date(event.target.value);
    const currentDate = new Date();

    // Check if the selected date is invalid
    if (selectedDate == "Invalid Date") {
      setIsDateValid(false); // Disable Submit button if date is invalid
    } else {
      // If the date is valid, set the validity state to true
      setIsDateValid(true);
    }

    // Check if the selected date is in the future
    if (selectedDate > currentDate) {
      toast.error(
        `${
          field === "start_date" ? "Start date" : "End date"
        } cannot be in the future.`
      );
      return;
    }

    // Cross-validate start_date and end_date
    setDateRange((prevDateRange) => {
      const otherDate = new Date(
        field === "start_date"
          ? prevDateRange.end_date
          : prevDateRange.start_date
      );

      const isStartDateInvalid =
        field === "start_date" && otherDate && selectedDate > otherDate;
      const isEndDateInvalid =
        field === "end_date" && otherDate && selectedDate < otherDate;

      if (isStartDateInvalid || isEndDateInvalid) {
        toast.error(
          `${
            field === "start_date" ? "Start date" : "End date"
          } is invalid compared to ${
            field === "start_date" ? "end date" : "start date"
          }.`
        );
        return prevDateRange; // Return the previous state without changes
      }

      return {
        ...prevDateRange,
        [field]: event.target.value,
      };
    });
  };

  const handleCustomRangeSubmit = useCallback(() => {
    setCustomDateRange(dateRange);
    const { start_date, end_date } = dateRange;
    getOrderCount(start_date, end_date);
    setOpenDialog(false);
    setSelectedFilter(start_date + " to " + end_date);
  }, [dateRange, getOrderCount, setOpenDialog, setSelectedFilter]);

  const handleDialogClose = () => {
    const today = new Date().toISOString().split("T")[0];
    setOpenDialog(false);
    setSelectedFilter("");
    // const start_date = today;
    // const end_date = today;
    // setCustomDateRange({
    //   start_date,
    //   end_date,
    // });
    // getOrderCount(start_date, end_date);
  };

  const handleSalesgroupChange = (selectedGroup) => {
    setSelectedSalesgroup(selectedGroup);
    setSelectedStockist("");
    setSelectedNames("");
    const selectedGroupData = salesgroups.find(
      (group) => group.salesgroup === selectedGroup
    );

    if (selectedGroupData) {
      setStockist(selectedGroupData?.stockist);
      setNames(selectedGroupData?.name);
    } else {
      setStockist([]);
      setNames([]);
    }
  };

  // default value selection
  // useEffect(() => {
  //   // Set the first salesgroup as the default selected value
  //   if (salesgroups.length == 1) {
  //     handleSalesgroupChange(salesgroups[0].salesgroup);
  //     handleStockistChange(salesgroups[0].user[0]);
  //     salesgroups[0].name.map((v) => {
  //       return handleNameChange(v);
  //     });
  //   }
  // });

  useEffect(() => {
    if (salesgroups.length === 1) {
      handleSalesgroupChange(salesgroups[0].salesgroup);
      handleStockistChange(salesgroups[0].user[0]);
      salesgroups[0].name.forEach((v) => {
        handleNameChange(v);
      });
      if (userRole != "admin") {
        setSelectedStockist(userRole);
      }
    }
  }, [salesgroups]);

  const handleStockistChange = (stockist) => {
    setSelectedStockist(stockist);
  };

  // const handlePayerIdChange = (payerId) => {
  //   setSelectedPayerIds(payerId);
  //   console.log('payerId', payerId)
  //   fetchData(selectedSalesgroup,selectedStockist, payerId, selectedNames)
  // };

  const handleNameChange = async (name) => {
    setSelectedNames(name);
  };

  const sortedSalesgroups = salesgroups.sort((a, b) => {
    if (a.salesgroup < b.salesgroup) return -1;
    if (a.salesgroup > b.salesgroup) return 1;
    return 0;
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    if (name === "salesgroup") {
      const selectedGroupData = salesgroups?.find(
        (group) => group.salesgroup === value
      );
      setNames(selectedGroupData?.name);
    }
  };

  const handleApplyFilter = () => {
    setCustomDateRange({
      start_date: formData.start_date,
      end_date: formData.end_date,
    });
    setSelectedSalesgroup(formData.salesgroup);
    setSelectedStockist(formData.stockist);
    setSelectedNames(formData.names);
    setOpenFilterDialog(false);
  };

  return (
    <>
      <Toaster />
      <Stack direction={"column"} gap={2} className="dashboard-main">
        {/* Count cards */}
        <Grid container spacing={2}>
          {smallCardData.map((item, index) => (
            <Grid item xs={12} sm={6} md={3} key={item.name + index}>
              <SmallCard
                icon={item.icon}
                count={item.count}
                textContent={item.name}
                myColor={item.color}
                BgColor={item.Bgcolor}
              />
            </Grid>
          ))}
        </Grid>

        {isMobile ? (
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#5052ba",
              color: "#fff",
              "&:hover": {
                backgroundColor: "#4042a3",
              },
            }}
            startIcon={<FilterListIcon />}
            onClick={() => setOpenFilterDialog(true)}
          >
            Filter
          </Button>
        ) : (
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={2}>
              <Tooltip
                title={
                  selectedFilter === "Custom Range"
                    ? customDateRange.start_date + customDateRange.end_date
                    : selectedFilter
                }
                placement="top"
              >
                <Autocomplete
                  disableClearable
                  fullWidth
                  id="combo-box-demo"
                  disablePortal
                  size="small"
                  sx={{ bgcolor: "#fff" }}
                  value={selectedFilter || null}
                  options={[
                    "Today",
                    "Weekly",
                    "Monthly",
                    "Yearly",
                    "Custom Range",
                  ]}
                  getOptionLabel={(option) => option}
                  onChange={(event, value) => handleFilterSelect(value)}
                  renderOption={(props, option) => (
                    <li
                      {...props}
                      style={{
                        pointerEvents: option === "Yearly" ? "none" : "auto",
                      }}
                    >
                      {option}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField {...params} label="Date Filter" />
                  )}
                />
              </Tooltip>
            </Grid>
            <Grid item xs={12} sm={3}>
              {userRole === "admin" && (
                <Autocomplete
                  fullWidth
                  disablePortal
                  id="combo-box-demo"
                  size="small"
                  sx={{ bgcolor: "#fff" }}
                  value={selectedSalesgroup || "All Salesgroup"}
                  options={sortedSalesgroups
                    .sort()
                    .map((option) => option.salesgroup)}
                  getOptionLabel={(option) => option}
                  onChange={(event, value) => handleSalesgroupChange(value)}
                  renderInput={(params) => (
                    <TextField {...params} label="Salesgroup" />
                  )}
                />
              )}

              {/* {localStorage.getItem("user") !== "admin" && (
              <Autocomplete
                fullWidth
                disablePortal
                id="combo-box-demo"
                size="small"
                sx={{ bgcolor: "#fff" }}
                value={selectedSalesgroup}
                options={sortedSalesgroups
                  .sort()
                  .map((option) => option.salesgroup)}
                getOptionLabel={(option) => option}
                onChange={(event, value) => handleSalesgroupChange(value)}
                renderInput={(params) => (
                  <TextField {...params} label="Salesgroup" />
                )}
                open={false} // Prevents the dropdown from opening
              />
            )} */}

              {userRole !== "admin" && (
                <Autocomplete
                  fullWidth
                  size="small"
                  disableClearable
                  sx={{ bgcolor: "#fff" }}
                  disablePortal
                  id="names"
                  value={selectedNames || null}
                  options={names.sort()}
                  getOptionLabel={(option) => option}
                  onChange={(event, value) => handleNameChange(value)}
                  renderInput={(params) => (
                    <TextField {...params} label="Names" />
                  )}
                />
              )}
            </Grid>

            {stockist.length > 0 && !selectedNames && userRole === "admin" && (
              <Grid item xs={12} sm={2}>
                <Autocomplete
                  fullWidth
                  size="small"
                  sx={{ bgcolor: "#fff" }}
                  disablePortal
                  id="stockist"
                  value={selectedStockist || null}
                  options={stockist.sort()}
                  getOptionLabel={(option) => option}
                  onChange={(event, value) => handleStockistChange(value)}
                  renderInput={(params) => (
                    <TextField {...params} label="Stockist" />
                  )}
                />
              </Grid>
            )}

            {/* {localStorage.getItem("user") !== "admin" && (
            <Grid item xs={12} sm={2}>
              <Autocomplete
                fullWidth
                size="small"
                sx={{ bgcolor: "#fff" }}
                disablePortal
                id="stockist"
                value={selectedStockist || null}
                options={stockist.sort()}
                getOptionLabel={(option) => option}
                onChange={(event, value) => handleStockistChange(value)}
                renderInput={(params) => (
                  <TextField {...params} label="Stockist" />
                )}
                open={false} // Prevents the dropdown from opening
              />
            </Grid>
          )} */}

            {names.length > 0 && !selectedStockist && userRole === "admin" && (
              <Grid item xs={12} sm={3}>
                <Autocomplete
                  fullWidth
                  size="small"
                  sx={{ bgcolor: "#fff" }}
                  disablePortal
                  id="names"
                  value={selectedNames || null}
                  options={names.sort()}
                  getOptionLabel={(option) => option}
                  onChange={(event, value) => handleNameChange(value)}
                  renderInput={(params) => (
                    <TextField {...params} label="Names" />
                  )}
                />
              </Grid>
            )}
            {/* 
          {localStorage.getItem("user") !== "admin" && (
            <Grid item xs={12} sm={3}>
              <Autocomplete
                fullWidth
                size="small"
                sx={{ bgcolor: "#fff" }}
                disablePortal
                id="names"
                value={selectedNames || null}
                options={names.sort()}
                getOptionLabel={(option) => option}
                onChange={(event, value) => handleNameChange(value)}
                renderInput={(params) => (
                  <TextField {...params} label="Names" />
                )}
              />
            </Grid>
          )} */}

            {/* {names.length > 0 && stockist.length > 0 && !selectedStockist && (
            <Grid item xs={12} sm={2}>
              <TextField
                fullWidth
                size="small"
                sx={{ bgcolor: "#fff" }}
                id="working-days"
                label="Working days"
                value={workingDaysData || 0}
              />
            </Grid>
          )} */}
          </Grid>
        )}

        {/* Graph and order table details */}
        <Box>
          <Grid container spacing={2}>
            {/* Left side Grid with TotalRevenue */}
            <Grid item xs={12} md={6} lg={3}>
              <Card sx={{ height: "100%" }}>
                <CardContent sx={{ height: "100%" }}>
                  <TotalRevenue
                    selectedSalesgroup={selectedSalesgroup}
                    selectedStockist={selectedStockist}
                    selectedNames={selectedNames}
                    customDateRange={customDateRange}
                  />
                </CardContent>
              </Card>
            </Grid>

            {/* ItemIdChart */}
            <Grid item xs={12} md={6} lg={9}>
              <Card sx={{ height: "100%" }}>
                <CardContent sx={{ height: "100%" }}>
                  <ItemIdChart
                    key={
                      customDateRange.start_date +
                      customDateRange.end_date +
                      selectedSalesgroup +
                      selectedStockist +
                      selectedNames
                    }
                    selectedSalesgroup={selectedSalesgroup}
                    selectedStockist={selectedStockist}
                    selectedNames={selectedNames}
                    customDateRange={customDateRange}
                  />
                </CardContent>
              </Card>
            </Grid>

            {/* WeeklyRevenueChart */}
            <Grid item xs={12} md={6} lg={6}>
              <Card sx={{ height: "100%" }}>
                <CardContent sx={{ height: "100%" }}>
                  <WeeklyRevenueChart
                    key={selectedSalesgroup + selectedStockist + selectedNames}
                    selectedSalesgroup={selectedSalesgroup}
                    selectedStockist={selectedStockist}
                    selectedNames={selectedNames}
                  />
                </CardContent>
              </Card>
            </Grid>

            {/* BarChart */}
            <Grid item xs={12} md={6} lg={6}>
              <Card sx={{ height: "100%" }}>
                <CardContent sx={{ height: "100%" }}>
                  <BarChart
                    key={
                      customDateRange.start_date +
                      customDateRange.end_date +
                      selectedSalesgroup +
                      selectedStockist +
                      selectedNames
                    }
                    selectedSalesgroup={selectedSalesgroup}
                    selectedStockist={selectedStockist}
                    selectedNames={selectedNames}
                    customDateRange={customDateRange}
                  />
                </CardContent>
              </Card>
            </Grid>

            {/* Right side Grid with StateOrderChart */}
            <Grid item xs={12}>
              <Card sx={{ height: "100%" }}>
                <CardContent sx={{ height: "100%" }}>
                  <StateOrderChart
                    key={
                      customDateRange.start_date +
                      customDateRange.end_date +
                      selectedSalesgroup +
                      selectedStockist +
                      selectedNames
                    }
                    selectedSalesgroup={selectedSalesgroup}
                    selectedStockist={selectedStockist}
                    selectedNames={selectedNames}
                    customDateRange={customDateRange}
                  />
                </CardContent>
              </Card>
            </Grid>

            {/* AreaWiseWeeklySalesBarChart */}
            <Grid item xs={12} md={4} lg={12}>
              <Card sx={{ height: "100%" }}>
                <CardContent sx={{ height: "100%" }}>
                  <AreaWiseWeeklySalesBarChart
                    selectedStockist={selectedStockist}
                    selectedSalesgroup={selectedSalesgroup}
                  />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>

        <CustomDialog
          open={openFilterDialog}
          onClose={() => setOpenFilterDialog(false)}
          title={`Filter`}
          fullscreen={false} // Set to true if you want the dialog to be fullscreen
          maxWidth="md" // Set the maxWidth as needed
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                size="small"
                margin="dense"
                label="Start Date"
                type="date"
                fullWidth
                name="start_date"
                value={formData.start_date}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                size="small"
                margin="dense"
                label="End Date"
                type="date"
                fullWidth
                name="end_date"
                value={formData.end_date}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                fullWidth
                disablePortal
                id="combo-box-demo"
                size="small"
                sx={{ bgcolor: "#fff" }}
                value={formData.salesgroup || null}
                options={salesgroups.map((option) => option.salesgroup)}
                getOptionLabel={(option) => option}
                onChange={(event, value) =>
                  handleChange({ target: { name: "salesgroup", value } })
                }
                renderInput={(params) => (
                  <TextField {...params} label="Salesgroup" />
                )}
              />
            </Grid>
            {stockist.length > 0 && (
              <Grid item xs={12} sm={2}>
                <Autocomplete
                  fullWidth
                  size="small"
                  sx={{ bgcolor: "#fff" }}
                  disablePortal
                  id="combo-box-demo"
                  value={selectedStockist || null}
                  options={stockist}
                  getOptionLabel={(option) => option}
                  onChange={(event, value) =>
                    handleChange({ target: { name: "stockist", value } })
                  }
                  renderInput={(params) => (
                    <TextField {...params} label="Stockist" />
                  )}
                />
              </Grid>
            )}

            {names.length > 0 && (
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  fullWidth
                  size="small"
                  sx={{ bgcolor: "#fff" }}
                  disablePortal
                  id="combo-box-demo"
                  value={formData.names || null}
                  options={names}
                  getOptionLabel={(option) => option}
                  onChange={(event, value) =>
                    handleChange({ target: { name: "names", value } })
                  }
                  renderInput={(params) => (
                    <TextField {...params} label="Names" />
                  )}
                />
              </Grid>
            )}
          </Grid>
          <Grid
            spacing={2}
            display="flex"
            justifyContent="flex-end"
            sx={{ mt: 2 }}
          >
            <Button
              sx={{
                backgroundColor: "#5052ba",
                color: "#fff",
                "&:hover": {
                  backgroundColor: "#4042a3",
                },
                ml: 2,
              }}
              variant="contained"
              onClick={handleApplyFilter}
            >
              Apply
            </Button>
          </Grid>
        </CustomDialog>
        <CustomDialog
          open={openDialog}
          onClose={handleDialogClose}
          title={`Select Date Range`}
          fullscreen={false} // Set to true if you want the dialog to be fullscreen
          maxWidth="sm" // Set the maxWidth as needed
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                size="small"
                margin="dense"
                label="Start Date"
                type="date"
                fullWidth
                value={dateRange.start_date}
                onChange={handleDateChange("start_date")}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                size="small"
                margin="dense"
                label="End Date"
                type="date"
                fullWidth
                value={dateRange.end_date}
                onChange={handleDateChange("end_date")}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
          <Box display="flex" justifyContent="flex-end" mt={2}>
            <Button
              variant="outlined"
              color="error"
              onClick={handleDialogClose}
              style={{ marginRight: "8px" }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={handleCustomRangeSubmit}
              sx={{
                backgroundColor: "#5052ba",
                color: "#fff",
                "&:hover": {
                  backgroundColor: "#4042a3",
                },
                ml: 2,
              }}
              disabled={!isDateValid}
            >
              Submit
            </Button>
          </Box>
        </CustomDialog>
      </Stack>
    </>
  );
};

export default Dashboard;
