// axiosInstance.js

import axios from "axios";

const axiosInstance1 = axios.create({
  baseURL: "http://143.244.136.36:8084",
  // http://orders.sapattea.net/dashboard
  // baseURL: "http://192.168.0.26:8080",
  // baseURL: "http://192.168.0.161:8081",
  // baseURL: "http://192.168.0.202:5000",
  // baseURL: "http://192.168.0.212:5000",
  headers: { "Content-Type": "application/json" },
});

const axiosInstance2 = axios.create({
  baseURL: "http://prod.sapattea.com:5001",
  // baseURL: 'http://192.168.0.202:5000',
  // baseURL: "http://192.168.0.90:5000",
  headers: { "Content-Type": "application/json" },
});

const axiosInstance3 = axios.create({
  // baseURL: 'http://192.168.0.202:5000',
  // baseURL: "http://192.168.0.90:5000",
  baseURL: "http://143.244.133.43:5000",
  headers: { "Content-Type": "application/json" },
});
export { axiosInstance1, axiosInstance2, axiosInstance3 };
