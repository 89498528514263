import React, { useState, useEffect } from "react";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import {
  useLocation,
  useParams,
  Link as RouterLink,
  useNavigate,
} from "react-router-dom";
import { getRequestInstanceTwo } from "../../apiServices/apiCall";
import * as XLSX from "xlsx";
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Typography,
  Breadcrumbs,
  Link as MuiLink,
  Box,
  styled,
  TextField,
  Button,
  Autocomplete,
  Grid,
  TableFooter,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import CustomDialog from "../../components/CustomDialog/CustomDialog";
import { CustomLoader } from "../../components/CustomLoader/CustomLoader";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import { makeStyles } from "@mui/styles";
import { Search } from "@mui/icons-material";

const StyledTableCell = styled(TableCell)(({ theme, isHeader, isFooter }) => ({
  backgroundColor: isHeader ? "#d3d4ee" : isFooter ? "#f5f5f5" : "transparent",
  color: theme.palette.common.black,
  padding: "12px",
  textAlign: "center",
  position: isHeader || isFooter ? "sticky" : "relative",
  top: isHeader ? 0 : "auto",
  bottom: isFooter ? 0 : "auto",
  zIndex: isHeader || isFooter ? 1 : "auto",
  fontWeight: 600,
  fontFamily: "Arial, sans-serif",
  fontSize: "14px",
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.background.default,
  },
  fontFamily: "Arial, sans-serif",
  fontSize: "14px",
  padding: "8px 12px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "12px",
    padding: "6px 10px",
  },
}));

const TableWrapper = styled(Box)({
  width: "100%",
  overflowX: "auto",
});

const useStyles = makeStyles(() => ({
  search: {
    margin: "0",
  },
  clearIcon: {
    cursor: "pointer",
  },
}));

const SalesOfferByPayer = () => {
  const { offer_name, id, salesgroup } = useParams();

  // const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const time_frame = query.get("time-frame");
  const startDate = query.get("start_date");
  const endDate = query.get("end_date");

  const [salesDetails, setSalesDetails] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState(
    time_frame || `${startDate} to ${endDate}`
  );
  const [openDialog, setOpenDialog] = useState(false);
  const [isLoadingTable, setIsLoadingTable] = useState(false);
  const [isLoadingGift, setIsLoadingGift] = useState(false);
  const [exportSalesDetails, setExportSalesDetails] = useState([]);
  const [exportGiftDetails, setExportGiftDetails] = useState([]);
  const [customDateRange, setCustomDateRange] = useState({
    start_date: startDate,
    end_date: endDate,
  });
  const [showClearIcon, setShowClearIcon] = useState("none");
  const { search, clearIcon } = useStyles();
  const [searchValue, setSearchValue] = useState("");

  const fetchData = async (start_date, end_date) => {
    setIsLoadingTable(true);
    try {
      const params = new URLSearchParams();
      params.append("start_date", start_date);
      params.append("end_date", end_date);
      params.append("offer_id", id);
      params.append("salesgroup_name", salesgroup);
      const url = `/api/salesgroup?${params.toString()}`;
      const response = await getRequestInstanceTwo(url);
      // console.log("Sales Details:", response.result);
      const uniqueSalesDetails = Array.from(
        response.result.map(JSON.stringify)
      ).map(JSON.parse);
      setSalesDetails(uniqueSalesDetails);
      setExportSalesDetails(uniqueSalesDetails);
    } catch (error) {
      console.error("Error fetching sales details:", error);
    } finally {
      setIsLoadingTable(false);
    }
  };

  useEffect(() => {
    if (selectedFilter !== null) {
      handleFilterSelect(selectedFilter);
    } else if (startDate !== null) {
      handleFilterSelect("Custom Range");
    }
  }, [selectedFilter]);

  const fetchGiftData = async (start_date, end_date) => {
    setIsLoadingGift(true);
    try {
      const params = new URLSearchParams();
      params.append("start_date", start_date);
      params.append("end_date", end_date);
      params.append("offer_id", id);
      params.append("salesgroup_name", salesgroup);
      const url = `/api/gift_count?${params.toString()}`;

      const response = await getRequestInstanceTwo(url);

      // Ensure uniqueness in your data if needed
      const uniqueSalesDetails = Array.from(
        response.result.map(JSON.stringify)
      ).map(JSON.parse);

      setExportGiftDetails(uniqueSalesDetails);
    } catch (e) {
      console.error("Error fetching sales details:", e);
      // Consider adding UI feedback for the user
    } finally {
      setIsLoadingGift(false);
    }
  };

  const handleDateChange = (field) => (event) => {
    setCustomDateRange({
      ...customDateRange,
      [field]: event.target.value,
    });
  };

  const handleCustomRangeSubmit = () => {
    const { start_date, end_date } = customDateRange;
    let searchParams = new URL(window.location);
    searchParams.searchParams.delete("time-frame");
    searchParams.searchParams.set("start_date", start_date);
    searchParams.searchParams.set("end_date", end_date);
    window.history.replaceState(null, "", searchParams);
    // setSelectedFilter("Custom Range");
    setOpenDialog(false);
    setSelectedFilter(start_date + " to " + end_date);

    fetchData(start_date, end_date);
    fetchGiftData(start_date, end_date);
  };

  const handleFilterSelect = (filter) => {
    const today = new Date();
    let start_date, end_date;
    switch (filter) {
      case "Today":
        start_date = end_date = today.toISOString().split("T")[0];
        break;
      case "Weekly":
        start_date = new Date(today.setDate(today.getDate() - 7))
          .toISOString()
          .split("T")[0];
        end_date = new Date().toISOString().split("T")[0];
        break;
      case "Monthly":
        start_date = new Date(today.setMonth(today.getMonth() - 1))
          .toISOString()
          .split("T")[0];
        end_date = new Date().toISOString().split("T")[0];
        break;
      case "Yearly":
        start_date = new Date(today.setFullYear(today.getFullYear() - 1))
          .toISOString()
          .split("T")[0];
        end_date = new Date().toISOString().split("T")[0];
        break;
      case "Custom Range":
        fetchData(customDateRange.start_date, customDateRange.end_date);
        fetchGiftData(customDateRange.start_date, customDateRange.end_date);
        return;
      default:
        fetchData(customDateRange.start_date, customDateRange.end_date);
        fetchGiftData(customDateRange.start_date, customDateRange.end_date);
        return;
    }

    setCustomDateRange({
      start_date,
      end_date,
    });

    fetchData(start_date, end_date);
    fetchGiftData(start_date, end_date);
  };

  const updateAutoComplete = (filter) => {
    if (filter !== null) {
      if (filter != "Custom Range") {
        setSelectedFilter(filter);
        const searchParams = new URL(window.location);
        searchParams.searchParams.set("time-frame", filter);
        searchParams.searchParams.delete("start_date");
        searchParams.searchParams.delete("end_date");
        window.history.replaceState(null, "", searchParams);
      } else {
        setOpenDialog(true);
      }
    }
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setSelectedFilter("");
  };

  // Aggregate sales details by salesgroup and type_Id
  const aggregatedSalesDetails = salesDetails.reduce((acc, detail) => {
    const key = `${detail.payerId}-${detail.type_Id}`;
    if (!acc[key]) {
      acc[key] = {
        payer: detail.payerId,
        type_Id: detail.type_Id,
        scheme_count: 0,
      };
    }
    acc[key].scheme_count += detail.scheme_count;
    return acc;
  }, {});

  // Prepare unique salesgroups and typeIds
  const uniqueSalesgroups = Object.values(aggregatedSalesDetails).reduce(
    (acc, detail) => {
      if (!acc.includes(detail.payer)) {
        acc.push(detail.payer);
      }
      return acc;
    },
    []
  );

  const typeIds = Object.values(aggregatedSalesDetails).reduce(
    (acc, detail) => {
      if (!acc.includes(detail.type_Id)) {
        acc.push(detail.type_Id);
      }
      return acc;
    },
    []
  );

  const aggregatedSalesDetailsAmount = {};

  // Preprocess the result to sum gift values by payerId and type_Id
  salesDetails.forEach((item) => {
    const key = `${item.payerId}-${item.type_Id}`;
    const giftValue = parseInt(item.gift_name.replace(/[^0-9]/g, "")) || 0;
    const totalGiftValue = giftValue * item.scheme_count;

    if (!aggregatedSalesDetailsAmount[key]) {
      aggregatedSalesDetailsAmount[key] = {
        payerId: item.payerId,
        type_Id: item.type_Id,
        scheme_count: item.scheme_count,
        total_gift: totalGiftValue,
      };
    } else {
      aggregatedSalesDetailsAmount[key].scheme_count += item.scheme_count;
      aggregatedSalesDetailsAmount[key].total_gift += totalGiftValue;
    }
  });

  // Aggregate total gift values by PayerId
  const totalGiftByPayerId = {};
  Object.values(aggregatedSalesDetailsAmount).forEach((detail) => {
    if (!totalGiftByPayerId[detail.payerId]) {
      totalGiftByPayerId[detail.payerId] = detail.total_gift;
    } else {
      totalGiftByPayerId[detail.payerId] += detail.total_gift;
    }
  });

  const totalOrderQuantityByPayerId = {};
  Object.values(salesDetails).forEach((detail) => {
    if (!totalOrderQuantityByPayerId[`${detail.payerId}`]) {
      totalOrderQuantityByPayerId[detail.payerId] = {};
      totalOrderQuantityByPayerId[detail.payerId][detail.order_id] =
        detail.order_quantity;
    } else {
      totalOrderQuantityByPayerId[detail.payerId][detail.order_id] =
        detail.order_quantity;
    }
  });

  // Calculate total counts by type_Id
  const totalCountsByTypeId = typeIds.reduce((acc, typeId) => {
    acc[typeId] = Object.values(aggregatedSalesDetails)
      .filter((detail) => detail.type_Id === typeId)
      .reduce((sum, detail) => sum + detail.scheme_count, 0);
    return acc;
  }, {});

  // Calculate overall total count
  const overallTotalCount = Object.values(aggregatedSalesDetails).reduce(
    (sum, detail) => sum + detail.scheme_count,
    0
  );

  //   Search functionality
  const handleChange = (event) => {
    setSearchValue(event.target.value);
    setShowClearIcon(event.target.value === "" ? "none" : "flex");
  };

  const handleClick = () => {
    setSearchValue("");
    setShowClearIcon("none");
    // console.log("clear icon clicked");
  };

  const normalizeSearchValue = (value) => {
    return value.trim().replace(/\s+/g, " ").toLowerCase();
  };

  // Filter uniqueSalesgroups based on searchValue
  const filteredSalesgroups = uniqueSalesgroups
    .filter((group) => {
      const salesGroup = group ? group.toLowerCase() : "";
      const normalizedgroupSearchValue = normalizeSearchValue(searchValue);
      const groupMatches = normalizedgroupSearchValue
        ? salesGroup.includes(normalizedgroupSearchValue)
        : true;
      return groupMatches;
    })
    .sort((a, b) =>
      (a || "").toLowerCase().localeCompare((b || "").toLowerCase())
    );

  // Export to Excel
  const exportExcel = (workbook) => {
    // const filename = `SalesOfferPayerDetails.xlsx`;

    // Prepare data for export
    const data = filteredSalesgroups.map((payer) => {
      const row = {
        Payer: payer,
      };
      typeIds.forEach((typeId) => {
        row[typeId] =
          aggregatedSalesDetails[`${payer}-${typeId}`]?.scheme_count || 0;
      });
      row["Total Count"] = Object.values(aggregatedSalesDetails)
        .filter((detail) => detail.payer === payer)
        .reduce((sum, detail) => sum + detail.scheme_count, 0);

      // Add gift amount if id is "PCV" or "SCV" or "SSD"
      if (id === "PCV" || id === "SCV" || id === "SSD") {
        row.giftAmount = totalGiftByPayerId[payer] || 0;
      }

      // Add order quantity if id is "PCV" or "SCV" or "SSD"
      if (id === "PCV" || id === "SCV" || id === "SSD") {
        row.orderQuantity =
          Object.values(totalOrderQuantityByPayerId[payer]).reduce((sum, a) => {
            return sum + a;
          }, 0) || 0;
      }

      return row;
    });

    // Calculate column totals
    const columnTotalRow = {
      Payer: "Total",
    };
    typeIds.forEach((typeId) => {
      columnTotalRow[typeId] = totalCountsByTypeId[typeId] || 0;
    });
    columnTotalRow["Total Count"] = overallTotalCount;

    // Add gift amount total if id is "PCV" or "SCV" or "SSD"
    if (id === "PCV" || id === "SCV" || id === "SSD") {
      columnTotalRow["giftAmount"] = filteredSalesgroups.reduce(
        (sum, payer) => {
          return sum + (totalGiftByPayerId[payer] || 0);
        },
        0
      );
    }

    // Add order quantity total if id is "PCV" or "SCV" or "SSD"
    if (id === "PCV" || id === "SCV" || id === "SSD") {
      columnTotalRow["orderQuantity"] = Object.values(totalOrderQuantityByPayerId).reduce(
        (payerSum, payer) => {
          return payerSum + Object.values(payer).reduce((sum, value) => sum + value, 0)
        },
        0
      );
    }

    // Add column totals row to data
    data.push(columnTotalRow);

    // Create a new workbook
    // const workbook = XLSX.utils.book_new();

    // Convert data to worksheet
    const worksheet = XLSX.utils.json_to_sheet(data);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sales Data");

    // Write the workbook as an Excel file
    // XLSX.writeFile(workbook, filename);
  };

  // Inside your SalesOfferByPayer component
  const exportDetailedExcel = () => {
    // display only month in created_at
    const getMonthFromDate = (dateString) => {
      const date = new Date(dateString);
      return date.toLocaleString("default", { month: "short" });
    };

    const getDateFromDate = (dateString) => {
      const date = new Date(dateString);
      return date.toLocaleString("default", {day: "2-digit", month: "short", year: "numeric"});
    };

    // Prepare data from exportSalesDetails
    const tableData = exportSalesDetails.map((detail) => {
      const rowData = {
        created_at: getDateFromDate(detail.created_at),
        salesgroup: detail.salesgroup,
        outletId: detail.outletId,
        outletName: detail.outletName,
        beatname: detail.beatname,
        payerId: detail.payerId,
        stokist_name: detail.stokist_name,
        Price_Id: detail.type_Id,
        scheme_count: detail.scheme_count,
        updated_date: getMonthFromDate(detail.updated_date),
      };

      if (detail.coupon_no) {
        rowData.coupon_no = detail.coupon_no;
      }

      if (detail.multi_sku) {
        rowData.multi_sku = detail.multi_sku;
      }

      if (detail.scheme_id) {
        rowData.scheme_id = detail.scheme_id;
      }
      if (detail.total_gifts) {
        rowData.total_gifts = detail.total_gifts;
      }
      if (detail.book_no) {
        rowData.book_no = detail.book_no;
      }
      if (id === "ODF" && detail.gift_value ? detail.gift_value : detail.gift_name) {
        rowData.gift_name = id === "ODF" && detail.gift_value ? detail.gift_value : detail.gift_name;
      }
      if (detail.secondary_phone_number) {
        rowData.AlternateNumber = detail.secondary_phone_number ?? 0;
      }
      if (detail.order_id) {
        rowData.order_id = detail.order_id ?? 0;
      }
      if (detail.order_quantity) {
        rowData.order_quantity = detail.order_quantity ?? 0;
      }
      if (detail.coupon_image || detail.coupon_image === null) {
        rowData.coupon_image = detail.coupon_image || "pending";
      }

      return rowData;
    });

    // Create worksheet
    const ws = XLSX.utils.json_to_sheet(tableData);

    // Create workbook and add the worksheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "SalesOfferByPayer");

    exportExcel(wb);
    exportGiftExcel(wb);

    // Save to file
    XLSX.writeFile(wb, `SalesOfferByPayer${offer_name}.xlsx`);
  };

  const exportGiftExcel = (workbook) => {
    const tableData = exportGiftDetails.map((detail) => {
      const { gift_name, gift_value, Count, Coupen_type } = detail;
      let Total = 0;

      // Use `gift_value` if `id` is "odf", otherwise use `gift_name`
      const giftDescriptor = id === "ODF" && gift_value ? gift_value : gift_name;

      if (giftDescriptor.startsWith("Rs.")) {
        // Extract the numerical value from the Rs. format
        const value = parseFloat(giftDescriptor.replace("Rs. ", ""));
        Total = Count * value;
      } else if (giftDescriptor.match(/^\d+ G X \d+$/)) {
        // Extract the multiplier for formats like 100 G X 1, 250 G X 2, etc.
        const multiplier = parseInt(giftDescriptor.split(" X ")[1]);
        Total = Count * multiplier;
      } else if (giftDescriptor.match(/^\d+ Kg X \d+ \(\d+\)$/)) {
        // Extract the value inside the parentheses for formats like 15 Kg X 5 (75)
        const matches = giftDescriptor.match(/\((\d+)\)$/);
        const multiplier = matches ? parseInt(matches[1]) : 0;
        Total = Count * multiplier;
      } else if (giftDescriptor.match(/^\d+ Kg$/)) {
        // Extract the value inside the parentheses for formats like 15 Kg
        const multiplier = parseInt(giftDescriptor.split(" ")[0]);
        Total = Count * multiplier;
      }

      return {
        Coupen_type: Coupen_type,
        "Gift Name": giftDescriptor,
        Count: Count,
        Total: Total,
      };
    });

    // Create worksheet from the tableData array with defined headers
    const ws = XLSX.utils.json_to_sheet(tableData);

    // Append the worksheet to the provided workbook
    XLSX.utils.book_append_sheet(workbook, ws, `Sales Gift Details`);

    // Uncomment the line below if you want to save the file directly within the function
    // XLSX.writeFile(workbook, `Sales Gift Details_${offer_name}.xlsx`);
  };

  return (
    <>
      <CustomLoader open={isLoadingTable} />
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Breadcrumbs aria-label="breadcrumb" sx={{ padding: "8px 0" }}>
            <MuiLink
              underline="hover"
              component={RouterLink}
              to="/sales-offer"
              color="inherit"
            >
              Sales Offer
            </MuiLink>
            <MuiLink
              underline="hover"
              component={RouterLink}
              to={
                selectedFilter !== "Custom Range" &&
                selectedFilter !==
                  `${customDateRange.start_date} to ${customDateRange.end_date}`
                  ? `/sales-offer/${offer_name}/${id}?time-frame=${selectedFilter}`
                  : `/sales-offer/${offer_name}/${id}?start_date=${customDateRange.start_date}&end_date=${customDateRange.end_date}`
              }
              color="inherit"
            >
              {offer_name}
            </MuiLink>
            <Typography color="textPrimary">{salesgroup}</Typography>
          </Breadcrumbs>
        </Grid>

        <Grid
          container
          justifyContent="flex-end"
          spacing={2}
          alignItems="center"
        >
          {isLoadingTable || isLoadingGift ? (
            <Grid
              item
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Button
                size="small"
                variant="contained"
                sx={{
                  backgroundColor: "#5052ba",
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: "#4042a3",
                  },
                  p: 1,
                  ml: 2,
                }}
                startIcon={<SaveAltIcon />}
                disabled
              >
                Loading...
              </Button>
            </Grid>
          ) : (
            <Grid
              item
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Button
                size="small"
                variant="contained"
                onClick={exportDetailedExcel}
                sx={{
                  backgroundColor: "#5052ba",
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: "#4042a3",
                  },
                  p: 1,
                  ml: 2,
                }}
                startIcon={<SaveAltIcon />}
              >
                Export All Details
              </Button>
            </Grid>
          )}
          {/* <Grid
            item
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <Button
              size="small"
              variant="contained"
              onClick={exportGiftExcel}
              sx={{
                backgroundColor: "#5052ba",
                color: "#fff",
                "&:hover": {
                  backgroundColor: "#4042a3",
                },
                ml: 2,
                p: 1,
              }}
              startIcon={<SaveAltIcon />}
            >
              Export Gift Count
            </Button>
          </Grid> */}
          <Grid item>
            <FormControl className={search} fullWidth>
              <TextField
                size="small"
                variant="outlined"
                sx={{ bgcolor: "#fff", width: 300 }}
                value={searchValue}
                placeholder="Search by payer"
                onChange={handleChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {searchValue ? (
                        <ClearIcon
                          className={clearIcon}
                          onClick={handleClick}
                        />
                      ) : (
                        <SearchIcon style={{ display: setShowClearIcon }} />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
          </Grid>

          <Grid item>
            <Tooltip
              title={
                selectedFilter === "Today"
                  ? customDateRange.start_date
                  : `${customDateRange.start_date} ${customDateRange.end_date}`
              }
              placement="top"
            >
              <Autocomplete
                disablePortal
                size="small"
                sx={{ bgcolor: "#fff", width: 200 }}
                id="combo-box-demo"
                value={selectedFilter}
                options={[
                  "Today",
                  "Weekly",
                  "Monthly",
                  "Yearly",
                  "Custom Range",
                ]}
                getOptionLabel={(option) => option}
                onChange={(event, value) => updateAutoComplete(value)}
                renderInput={(params) => (
                  <TextField {...params} label="Date Filter" />
                )}
              />
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>

      <Typography variant="h5" gutterBottom>
        Sales Offer Payer Details
      </Typography>

      <TableWrapper>
        <TableContainer
          component={Paper}
          sx={{ maxHeight: "450px", overflow: "auto" }}
        >
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <StyledTableCell isHeader>Payer</StyledTableCell>
                {typeIds.map((typeId) => (
                  <StyledTableCell isHeader key={typeId}>
                    {typeId}
                  </StyledTableCell>
                ))}
                <StyledTableCell isHeader>Total Count</StyledTableCell>
                {(id == "PCV" || id == "SCV" || id == "SSD") && (
                  <StyledTableCell isHeader>Gift Amount</StyledTableCell>
                )}
                {(id == "PCV" || id == "SCV" || id == "SSD") && (
                  <StyledTableCell isHeader>Order Quantity</StyledTableCell>
                )}
                <StyledTableCell isHeader>Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredSalesgroups.map((payer, index) => (
                <StyledTableRow key={index}>
                  <TableCell align="center">{payer}</TableCell>
                  {typeIds.map((typeId) => (
                    <TableCell align="center" key={`${payer}-${typeId}`}>
                      {aggregatedSalesDetails[`${payer}-${typeId}`]
                        ?.scheme_count || 0}
                    </TableCell>
                  ))}
                  <TableCell align="center">
                    {Object.values(aggregatedSalesDetails)
                      .filter((detail) => detail.payer === payer)
                      .reduce((sum, detail) => sum + detail.scheme_count, 0)}
                  </TableCell>

                  {/* Total Gift Value */}
                  {(id === "PCV" || id === "SCV" || id == "SSD") && (
                    <TableCell align="center">
                      {/* Total Gift Amount by Payer ID */}
                      Rs. {totalGiftByPayerId[payer] || 0}
                    </TableCell>
                  )}

                  {/* Order Quantity */}
                  {(id == "PCV" || id == "SCV" || id == "SSD") && (
                    <TableCell align="center">
                      {Object.values(totalOrderQuantityByPayerId[payer]).reduce(
                        (sum, a) => {
                          return sum + a;
                        },
                        0
                      )}
                    </TableCell>
                  )}
                  <TableCell align="center">
                    <Button
                      variant="outlined"
                      size="small"
                      color="success"
                      component={RouterLink}
                      to={(() => {
                        if (
                          selectedFilter !=
                            `${customDateRange.start_date} to ${customDateRange.end_date}` &&
                          selectedFilter != "Custom Range"
                        ) {
                          return `/sales-offer/${offer_name.replace(
                            / /g,
                            ""
                          )}/${id}/${salesgroup}/${payer}?time-frame=${selectedFilter}`;
                        } else {
                          return `/sales-offer/${offer_name.replace(
                            / /g,
                            ""
                          )}/${id}/${salesgroup}/${payer}?start_date=${
                            customDateRange.start_date
                          }&end_date=${customDateRange.end_date}`;
                        }
                      })()}
                    >
                      View
                    </Button>
                  </TableCell>
                </StyledTableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <StyledTableCell isFooter>Total</StyledTableCell>
                {typeIds.map((typeId) => (
                  <StyledTableCell isFooter key={typeId}>
                    {filteredSalesgroups.reduce((total, payer) => {
                      return (
                        total +
                        (aggregatedSalesDetails[`${payer}-${typeId}`]
                          ?.scheme_count || 0)
                      );
                    }, 0)}
                  </StyledTableCell>
                ))}
                <StyledTableCell isFooter>
                  {filteredSalesgroups.reduce((total, payer) => {
                    return (
                      total +
                      Object.values(aggregatedSalesDetails)
                        .filter((detail) => detail.payer === payer)
                        .reduce((sum, detail) => sum + detail.scheme_count, 0)
                    );
                  }, 0)}
                </StyledTableCell>
                {id == "PCV" || id == "SCV" || id == "SSD" ? (
                  <StyledTableCell isFooter>
                    Rs{" "}
                    {filteredSalesgroups.reduce((sum, payerId) => {
                      return sum + totalGiftByPayerId[payerId];
                    }, 0)}
                  </StyledTableCell>
                ) : (
                  <StyledTableCell isFooter />
                )}
                {(id == "PCV" || id == "SCV" || id == "SSD") && (
                  <StyledTableCell isFooter>
                    {Object.values(totalOrderQuantityByPayerId).reduce(
                      (payerSum, payerId) => {
                        return (
                          payerSum +
                          Object.values(payerId).reduce(
                            (sum, value) => sum + value,
                            0
                          )
                        );
                      },
                      0
                    )}
                  </StyledTableCell>
                )}
                {(id == "PCV" || id == "SCV" || id == "SSD") && <StyledTableCell isFooter />}
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </TableWrapper>

      <CustomDialog
        open={openDialog}
        onClose={handleDialogClose}
        title="Select Custom Date Range"
      >
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <TextField
            label="Start Date"
            type="date"
            value={customDateRange.start_date}
            onChange={handleDateChange("start_date")}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            label="End Date"
            type="date"
            value={customDateRange.end_date}
            onChange={handleDateChange("end_date")}
            InputLabelProps={{ shrink: true }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleCustomRangeSubmit}
          >
            Apply
          </Button>
        </Box>
      </CustomDialog>
    </>
  );
};

export default SalesOfferByPayer;
